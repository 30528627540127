<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="white"
    flat
    class="mx-4 mb-4 rounded-lg"
  >
    <v-breadcrumbs :items="breadcrumbs">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item>
          <span class="text--body-5-18">
            {{ $t(item.key).toUpperCase() }}
            {{ item.name ? item.name.toUpperCase() : '' }}
          </span>
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <v-spacer />
    <v-btn
      class="ml-4 custom-app-bar-profile"
      :ripple="false"
      min-width="0"
      text
    >
      <v-avatar color="indigo" class="mx-2">
        <v-icon dark> mdi-account-circle </v-icon>
      </v-avatar>
      <v-layout column align-start>
        <label>{{ fullname }}</label>
      </v-layout>
    </v-btn>

    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn small fab min-width="0" text v-bind="attrs" v-on="on">
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </template>

      <v-list class="py-0">
        <v-list-item @click="onGotoAccount">
          <v-list-item-icon class="mr-3">
            <v-icon>mdi-account-circle-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="$t('account')" />
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="handleLogout">
          <v-list-item-icon class="mr-3">
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="$t('logout')" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
// Utilities
import { mapState, mapActions } from 'vuex'
import { localize } from 'vee-validate'
import constants from '@/constants'
import routePaths from '@/router/routePaths'
import { AuthService } from '@/services/authService'
import { StorageService } from '@/services/storageService'
import { DataBreadCrumbUtils } from '@/helpers/dataBreadCrumbUtils'

export default {
  name: 'DashboardCoreAppBar',
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    profileInfo: null,
    profilePath: routePaths.ACCOUNT,
    notifications: ['You have 5 new tasks', 'Another Notification'],
    langs: [
      {
        icon: require('@/assets/langs/vn.png'),
        text: 'Tiếng Việt',
        value: 'vi'
      },
      {
        icon: require('@/assets/langs/us.png'),
        text: 'Tiếng Anh',
        value: 'en'
      }
    ]
  }),
  computed: {
    ...mapState('layout', ['drawer']),
    breadcrumbs() {
      return this.$store.state.layout.breadcrumbs
    },
    fullname: {
      get() {
        return this.$store.state.layout.fullname
      },
      set(val) {
        this.setFullName(val)
      }
    }
  },
  watch: {
    "$route.path"(to) {
      this.addBreadCrumbs(to);
    }
  },
  created() {
    // Set default lang
    localize(this.$root.$i18n.locale)
  },
  beforeMount() {
    this.initFullname()
  },
  methods: {
    ...mapActions('layout', ['setDrawer', 'setBreadcrumbs', 'setFullName']),
    switchLang(lang) {
      this.$root.$i18n.locale = lang
      this.locale = lang
      localStorage.setItem(constants.LANGUAGE, lang)
    },
    onGotoAccount() {
      this.$router.push(routePaths.ACCOUNT)
      this.addBreadCrumbs(routePaths.ACCOUNT)
    },
    addBreadCrumbs(pathActive) {
      const breadCrumbs =
        DataBreadCrumbUtils.getBreadCrumbByPathActive(pathActive)
      this.setBreadcrumbs(breadCrumbs)
    },
    initFullname() {
      const currUser = StorageService.getCurrentUser()
      this.profileInfo = currUser
      this.setFullName(currUser.name)
    },
    async handleLogout() {
      const response = await AuthService.revoke()
      if (response) {
        window.location.reload()
      }
    }
  }
}
</script>
